/* Global Styles */
.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto; /* Allows for scrolling if the combined content exceeds the viewport height */
}

body {
  font-family: "Work Sans", sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none
}

/* Header Styles */
.header {
  background-color: #f0f0ea;
  padding: 1rem;
  color: #535353;
  display: flex;
  align-items: center; /* Aligns the logo and nav items vertically */
  justify-content: space-between; /* Separates the logo and nav items */
  position: sticky;
  top: 0;
  z-index: 1000;
  font-weight: 400;
}

.logo-container {
  padding-right: 1rem; /* Adds some padding between the logo and nav items */
}

.logo {
  height: 18px; /* Adjust based on your logo's size */
  width: auto; /* Keeps the logo's aspect ratio */
}

nav {
  display: flex;
  justify-content: flex-end; /* Right-align nav items */
}

.nav-links {
  list-style: none;
  padding: 0;
  display: flex; /* Horizontal nav items */
  align-items: center; /* Vertical alignment */
}

.nav-link, .nav-link:link, .nav-link:visited {
  color: #535353;
  text-decoration: none;
  margin-left: 1rem;
  font-weight: 500; /* Bold nav links */
}

.nav-link:hover {
  color: #7a7a7a; /* Light red for hover, change as needed */
}

.nav-link:active {
  color: #ff9999; /* Darker red for active/click, change as needed */
}

/* Footer Styles */
.footer {
  background-color: #191919;
  color: #f0f0ea;
  text-align: center;
  position: fixed;
  padding: 8px;
  bottom: 0;
  width: 100%;
  flex-shrink: 0;
}

.footer-text {
  margin: 0;
  font-size: 12px;
}

/* MainBody and content styles with responsiveness adjustments */
.main-body {
  /* This allows the main content to fill the remaining space */
  flex-grow: 1;
  overflow: auto; /* Allows scrolling within the main content area if its content exceeds its container */
  padding: 1rem;
  background-color: #f0f0ea; /* Dark charcoal background */
  color: #070707; /* Assuming you want the text color to be white */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
}

.content {
  text-align: center;
  color: #070707;
  max-width: 85%; /* Adjust width to not exceed 85% of the screen */
  margin: 0 auto; /* Center-align content */
}

.main-header {
  font-size: clamp(45px, 5vw, 80px); /* Adjusts between 32px and 60px based on viewport width */
  line-height: 1.2;
  font-weight: 600;
}

.sub-header {
  font-size: 24px;
  line-height: 1.3;
}

.main-body-h2 {
  margin-top: 125px;
  margin-bottom: 100px;
  font-weight: 600;
  font-size: 35px;
  line-height: 1.4;
}

/* Media query for smaller screens */
@media (max-width: 500px) {
  .main-body-h2 {
    font-size: 24px;  /* Smaller font size on small screens */
    line-height: 1.2; /* Smaller line height on small screens */
  }
}


/* Pill-shaped input and button with enhanced styles */
.email-input, .submit-button, .cool-button {
  padding: 15px 20px; /* Increased padding */
  margin: 10px; /* Uniform margin */
  border: none;
  border-radius: 50px; /* Pill shape */
  outline: none;
  font-size: 16px; /* Adjusted font size for better readability */
}

.email-input, .submit-button, .cool-button {
  max-width: 85%; /* Ensure elements do not exceed 85% width */
  width: auto; /* Adjust width automatically */
}

.email-input {
  flex-grow: 2; /* Input field takes more space */
  border: 1px solid #ccc; /* Optional subtle border */
}

.submit-button, .cool-button {
  flex-grow: 1;
  cursor: pointer;
  background-color: #ff385c;
  color: white;
  font-weight: bold; /* Bold button fonts */
  transition: background-color 0.3s, transform 0.3s;
}

.submit-button:hover, .cool-button:hover {
  background-color: #ff576b;
  transform: scale(1.05);
}

/* Responsive and dynamic layout for SignupForm */
.signup-form {
  display: flex;
  flex-wrap: wrap; /* Ensures responsiveness */
  gap: 10px; /* Space between elements */
  justify-content: center; /* Center-align form contents */
  max-width: 85%; /* Max width to not exceed 85% of the screen */
  margin: 20px auto; /* Increased margin for better spacing */
}

/* Adjustments for smaller screens */
@media (max-width: 600px) {
  .signup-form, .content {
    flex-direction: column; /* Stack input field and button vertically */
  }

  .email-input, .submit-button, .cool-button {
    flex-grow: 0; /* Resets growth to make elements equal */
    width: 100%; /* Full width for smaller screens */
    margin: 10px auto; /* Ensure central alignment */
  }
}

/* Subtle Hover Animation for Icons in Features Section */
.feature-icon:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

/* Features Section - Responsive adjustments */
.features-section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; /* Allows wrapping for responsiveness */
  margin: 20px 0;
}

.feature {
  text-align: center;
  width: 30%; /* Adjust based on design preference */
  min-width: 200px; /* Minimum width to maintain layout integrity */
  padding: 15px;
}

.feature-icon {
  width: 60px; /* Adjust size as needed */
  margin-bottom: 10px;
}

.signup-banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ff385c;
  color: 	#f0f0ea;
  text-align: center;
  padding: 15px; /* Increased padding */
  z-index: 1001; /* Ensure it's above other content */
  font-size: 18px; /* Slightly larger text */
  font-weight: bold; /* Heavier weight */
  opacity: 0.9; /* Slight opacity */
}

.banner-close {
  position: absolute;
  right: 20px;
  padding-right: 25px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  color: 222222;
  font-size: 24px; /* Larger close button */
  cursor: pointer;
}

/*Top Fundraising Banner*/
.top-banner {
  background-color: #60aaf2; /* Bright blue background */
  color: white;
  padding: 15px 20px;
  text-align: center;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.learn-more-text {
  color: white;
  cursor: pointer; /* Gives a visual cue that it's clickable */
  text-decoration: underline;  /* Optional, for a link-like appearance */
  font-weight: bold; /* Ensures it's bold as specified */
}

.banner-text-container {
  flex-grow: 1; /* Allows the container to grow and take up space, helping center the content */
  text-align: center; /* Ensures the text inside the container is centered */
}

.close-banner {
  font-size: 24px; /* Adjust size as necessary */
  cursor: pointer;
  background-color: transparent; /* Make it stand out */
  color: white; /* Ensure text is white */
  border: none;
  border-radius: 50%; /* Optional: makes the button circular */
  padding: 0 12px; /* Adjust padding to ensure content is centered */
  line-height: 24px; /* Adjust line height to match font size for vertical alignment */
  height: 24px; /* Match line height for a perfect circle if border-radius is 50% */
  margin-left: auto; /* Pushes the button to the far right */
}

/*Contact Page*/

.contact-page {
  background-color: #f0f0ea; /* Same as main page background */
  color: #070707; /* Same as main body text color */
  padding: 1rem;
  min-height: 95vh; /* Ensures it stretches to the bottom of the viewport */
  display: flex;
  flex-direction: column; /* Stacks children vertically */
  justify-content: top; /* Centers content vertically if less content */
}

.contact-page h1 {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.contact-page h2 {
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.contact-page p {
  font-size: 1.25rem;
  margin-top: 0;
}

.contact-page a {
  color: #070707; /* Ensures links are the same color as the text */
  text-decoration: underline;
}

.learn-more-page section {
  margin-bottom: 2rem; /* Adds more space after each section */
}

.secret-code-link {
  text-decoration: none; /* Removes underline */
}

.secret-code-button {
  position: absolute; /* Changed from fixed to absolute */
  bottom: 15%; /* Adjusted to position it at 90% height from the top of the main content */
  right: 20px; /* Keep it to the right */
  padding: 12px 20px;
  background-color: #d9d9d4;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  z-index: 10; /* Adjust based on your z-index needs */
  transition: background-color 0.3s ease;
  opacity: 0.91;
}

.secret-code-button:hover {
  background-color: #ffffff; /* Slightly lighter on hover */
  color: #d9d9d4;
}

/*Got a Code Page*/
.enter-code-button {
  padding: 15px 20px; /* Increased padding */
  margin: 10px; /* Uniform margin */
  border: none;
  border-radius: 50px; /* Pill shape */
  outline: none;
  font-size: 16px; /* Adjusted font size for better readability */
  flex-grow: 1;
  cursor: pointer;
  background-color: #FF6F61;
  color: white;
  font-weight: bold; /* Bold button fonts */
  transition: background-color 0.3s, transform 0.3s;
}

.enter-code-button:hover {
  background-color: rgb(244, 129, 119);
  transform: scale(1.05);
}

/*Feature Section*/
.image-text-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 10px;
  margin-bottom: 100px;
  gap: 20px;
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
}

.section-image {
  flex: 1;
  max-width: 50%;
}

.section-image img {
  width: 100%;
  height: auto;
  max-width: 700px;
  object-fit: contain;
}

.section-text {
  flex: 1;
  max-width: 50%;
  padding: 0 20px;
}

.section-text h2 {
  font-size: 1.5em; /* Adjust size as needed */
  margin-bottom: 0.5em;
}

.section-text p {
  font-size: 1em; /* Adjust size as needed */
  line-height: 1.6;
}

/* Responsive design */
@media (max-width: 768px) {
  .image-text-section {
    flex-direction: column;
  }

  .section-image, .section-text {
    max-width: 100%;
  }

  .section-text h2 {
    font-size: 1.2em;
  }

  .section-text p {
    font-size: 0.9em;
  }
}

.feature-section-two-learn-more-button {
  padding: 12px 16px; /* Increased padding */
  margin: 10px; /* Uniform margin */
  border: none;
  border-radius: 50px; /* Pill shape */
  outline: none;
  font-size: 16px; /* Adjusted font size for better readability */
  flex-grow: 1;
  cursor: pointer;
  background-color: #AAA6B9;
  color: white;
  font-weight: bold; /* Bold button fonts */
  transition: background-color 0.3s, transform 0.3s;

}

.feature-section-two-learn-more-button:hover {
  background-color: #96939f;
  transform: scale(1.05);
}

/*FAQ Page*/
.faq-page-container {
  width: 90%;
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.faq-header {
  text-align: center;
  color: #333;
}

.faq-item {
  margin-top: 10px;
}

.faq-question {
  width: 100%;
  padding: 10px;
  background-color: #60aaf2;
  color:#ffffff;
  font-weight: 500;
  border: none;
  text-align: left;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.faq-question:hover {
  background-color: #4688ca;
}

.faq-answer {
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
  line-height: 1.3;
}

.faq-contact-support {
  padding-top: 40px;
  padding-bottom: 50px;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.faq-support-link {
  font-weight: 600;
}

.faq-section {
  margin-top: 20px;
  padding: 10px;
  border-radius: 8px;
}

.faq-section-title {
  color: #333;
  margin-bottom: 15px;
  text-align: left;
}

/* You might want to consider adding an icon or a slight style modification to indicate expandability of questions */
.faq-question::after {
  content: '+';
  float: right;
  font-size: 1.2em;
}

/* Adjust icon/content when question is open */
.faq-question[aria-expanded="true"]::after {
  content: '-';
}

/* New classes for section titles */
.faq-section-title {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  padding: 0.5em 0;
  border-bottom: 1px solid #ccc;
}